<template>
  <div class="section_g">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">결재현황</strong>
      </div>
      <!-- <div v-if="isApprover" class="pos_right">
        <button
          v-if="apprType != 'F'"
          type="button"
          class="btn_medium btn_secondary"
          style="width: 104px"
          @click.prevent="$emit('searchOrganization', 'approver')"
        >
          결재자 추가
        </button>
      </div>
      <div v-if="isViewer" class="pos_right">
        <button
          type="button"
          class="btn_medium btn_secondary"
          style="width: 104px"
          @click.prevent="$emit('searchOrganization', 'viewer')"
        >
          열람자 추가
        </button>
      </div> -->
      <!-- // area_status -->
      <!--          <div class="wrap_btn" v-if="canApproveEdit">-->
      <!--            <button type="button" class="btn_comm bg_type3 type_small" @click.prevent="searchOrganization">상위결재자 변경</button>-->
      <!--          </div>-->
    </div>
    <!-- 결재자 목록 -->
    <pay-status-approver
      :items="approvalList"
      :aprvl-line-type="aprvlLineType"
      :aprvl-status="approvalStatus"
      :aprvl-line-flag="aprvlLineFlag"
      :drafter="drafter"
      :date-time-format="dateTimeFormat"
    />
    <!-- 참조자 목록 -->
    <pay-status-referencer :items="referencerList" />
    <!-- 열람자 목록 -->
    <pay-status-viewer
      v-if="isViewer"
      :items="viewerList"
      :date-time-format="dateTimeFormat"
      :is-viewer-edit="isViewerEdit"
      @delete="onClickDelViewer"
    />
    <!-- 추가 결재자 목록 -->
    <div v-if="isApprover" class="box_addition">
      <strong class="screen_out">추가 결재자 목록</strong>
      <dl class="list_addition">
        <dt>추가 결재자</dt>
        <dd>
          <div class="area_addition">
            <div
              v-for="(addApprover, index) in addApproverList"
              :key="index"
              class="group_addition"
            >
              <span class="txt_addition"
                >{{ addApprover.accountId }} ({{ addApprover.empName }})
                {{ addApprover.insertDatetime | moment(dateTimeFormat) }}</span
              ><!-- href에 파일 경로 삽입 -->
              <button
                type="button"
                class="link_del"
                @click="$emit('popDeleteapprover', addApprover)"
              >
                <span class="ico_account">삭제</span>
              </button>
            </div>
          </div>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { commConst } from "@/_approval/const/const.js";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommToastMixin } from "@/_approval/mixins/common/commToast.js";
import { default as CommMomentMixin } from "@/_approval/mixins/common/commMoment.js";
import { aprvlLineType, approvalStatus } from "@/_approval/const/const";
import PayStatusApprover from "@/_approval/components/shared/view/payStatus/PayStatusApprover";
import PayStatusReferencer from "@/_approval/components/shared/view/payStatus/PayStatusReferencer";
import PayStatusViewer from "@/_approval/components/shared/view/payStatus/PayStatusViewer";

export default {
  name: "PayStatusStatement",
  components: { PayStatusViewer, PayStatusReferencer, PayStatusApprover },
  mixins: [CommLayerMixin, CommToastMixin, CommMomentMixin],
  props: {
    docFlag: {
      // 읽음 처리용으로 보임
      type: String,
      default: "",
    },
    docNo: {
      // 상위결재선 변경용으로 보임
      type: String,
      default: "",
    },
    approvalLine: {
      // 결재선 데이터
      type: Array,
      default: () => [],
    },
    apprType: String,
    addApproverList: Array,
    viewerList: {
      // 열람자 데이터
      type: Array,
      default: () => [],
    },
    canApproveEdit: {
      // 상위결재선 변경 버튼 노출 여부
      type: Boolean,
      default: false,
    },
    isApprover: { type: Boolean, default: false },
    isViewer: {
      // 열람자 노출여부
      type: Boolean,
      default: false,
    },
    isViewerEdit: {
      // 열람자 노출여부
      type: Boolean,
      default: false,
    },
    dateTimeFormat: {
      // 날짜 포맷
      type: String,
      default: commConst.DATETIME_FORMAT,
    },
  },
  data() {
    return {
      aprvlLineType: aprvlLineType,
      approvalStatus: approvalStatus,
      aprvlLineFlag: {
        [aprvlLineType.DRAFT]: "기안자",
        [aprvlLineType.APPROVAL]: "승인자",
        [aprvlLineType.REFERENCE]: "참조자",
        [aprvlLineType.VIEWER]: "열람자",
      },
      approvalLineWithReason: [],
    };
  },
  computed: {
    approvalList() {
      return this.approvalLine.filter(
        (line) =>
          !(
            line.aprvlLineType === aprvlLineType.REFERENCE ||
            line.aprvlLineType === aprvlLineType.VIEWER
          ),
      );
    },
    referencerList() {
      return this.approvalLine.filter((line) => line.aprvlLineType === aprvlLineType.REFERENCE);
    },
    drafter() {
      return this.approvalLine.filter((line) => line.aprvlLineType === aprvlLineType.DRAFT)[0];
    },
    // viewerList() {
    //   return this.approvalLine.filter(
    //     (line) => line.aprvlLineType === aprvlLineType.VIEWER,
    //   );
    // },
  },
  // watch: {
  //   approvalLine: {
  //     immediate: true,
  //     handler(e) {
  //       console.log('watch', e);
  //       this.mergeDelegateReason();
  //     },
  //   },
  // },
  mounted() {
    // console.log({
    //   docFlag: this.docFlag,
    //   docNo: this.docNo,
    //   approvalLine: this.approvalLine,
    //   canApproveEdit: this.canApproveEdit,
    //   isViewer: this.isViewer,
    //   viewerList: this.viewerList,
    //   dateTimeFormat: this.dateTimeFormat,
    // });
  },
  methods: {
    // mergeDelegateReason() {
    //   console.log('mergeDelegateReason');
    //   this.approvalLineWithReason = [];
    //   this.approvalLine.forEach((approval, idx) => {
    //     this.approvalLineWithReason.push(approval);
    //   });
    // },
    searchOrganization() {
      this.$emit("searchOrganization");
    },
    onClickDelViewer(item) {
      this.$emit("popDeleteViewer", item);
    },
  },
};
</script>
<style scoped>
.pos_right .btn_medium {
  width: 104px;
}
</style>
