<template>
  <div class="area_status">
    <ul class="list_status">
      <li v-for="line in items" :key="line.aprvlOrder" :class="makeApprLiClass(line)">
        <template v-if="aprvlLineType.REFERENCE !== line.aprvlLineType">
          <div class="item_status">
            <em class="tit_approval">{{ line.aprvlLineTypeName }}</em>
            <div class="txt_nick">{{ line.accountId }} ({{ line.empName }})</div>
            <em class="screen_out">부서명</em>
            <div class="txt_team">
              {{ unescape(line.deptName) }}
            </div>
            <em class="screen_out">결재 상태</em>
            <div :class="['tag_status', makeApprStatusClass(line, false)]">
              {{ makeApprStatusName(line, false) }}
            </div>
            <!-- 시간노출 -->
            <!-- TODO : 대결 -->
            <template v-if="line.delegateEmpNo != null && line.delegateEmpNo !== ''">
              <div class="info_reason">
                <a href="javascript:void(0);" class="link_reason">위임사유</a>
                <div class="box_reason">
                  {{ line.reason }}
                </div>
              </div>
            </template>
            <!-- TODO : 보류 -->
            <template v-else-if="line.holdYn === 'Y'">
              <div class="info_reason">
                <a href="javascript:void(0);" class="link_reason">보류사유</a>
                <div class="box_reason">
                  {{ line.reason }}
                </div>
              </div>
            </template>
            <!-- 상신 -->
            <template v-else-if="line.aprvlLineType === aprvlLineType.DRAFT">
              <em class="screen_out">결재 일시</em>
              <div class="txt_time">
                {{ formatDatetime(line.regDate, dateTimeFormat) }}
              </div>
            </template>
            <!-- 결재, 승인, 반려 -->
            <template v-else-if="isShowDateTime(line)">
              <em class="screen_out">결재 일시</em>
              <div class="txt_time">
                {{ formatDatetime(line.modDate, dateTimeFormat) }}
              </div>
            </template>
            <div class="ico_account ico_arr" />
          </div>
          <!-- TODO : 우측박스 대결 -->
          <div v-if="line.dlgtEmpNo != null && line.dlgtEmpNo !== ''" class="item_status">
            <em class="tit_approval">위임자</em>
            <div class="txt_nick">{{ line.dlgtAccountId }} ({{ line.dlgtEmpName }})</div>
            <em class="screen_out">부서명</em>
            <div class="txt_team">
              {{ line.dlgtDeptName }}
            </div>
            <em class="screen_out">결재 상태</em>
            <div :class="['tag_status', makeApprStatusClass(line, false)]">
              {{ makeApprStatusName(line, false) }}
            </div>
            <em class="screen_out">결재 일시</em>
            <div class="txt_time">
              {{ formatDatetime(line.aprvlStatusDate, dateTimeFormat) }}
            </div>
            <div class="ico_account ico_arr" />
          </div>
          <!-- TODO : 우측박스 보류 -->
          <div v-if="line.holdYn === 'Y'" class="item_status">
            <em class="tit_approval">보류</em>
            <div class="txt_period">
              ~{{ formatDatetime(line.aprvlStatusDate, dateTimeFormat) }}
            </div>
            <em class="screen_out">결재 상태</em>
            <div class="ico_account" />
            <em class="screen_out">결재 일시</em>
            <div class="txt_time">
              {{ formatDatetime(line.aprvlStatusDate, dateTimeFormat) }}
            </div>
            <div class="ico_account ico_arr" />
          </div>
          <!-- TODO : 우측박스 기안자 반려 / 이전 결재자 반려 -->
          <!-- line.aprvlStatus === aprvlStatus.DRAFT_REJECT || line.aprvlStatus === aprvlStatus.DRAFT_BEFORE_REJECT -->
          <div v-if="false" class="item_status">
            <em class="tit_approval">기안자</em>
            <div class="txt_nick">{{ drafter.accountId }} ({{ drafter.empName }})</div>
            <em class="screen_out">부서명</em>
            <div class="txt_team">
              {{ drafter.deptName }}
            </div>
            <!--          <em class="screen_out">결재 상태</em>-->
            <!--          <div class="ico_account"></div>-->
            <!--          <em class="screen_out">결재 일시</em>-->
            <!--          <div class="txt_time">-->
            <!--            {{ formatDatetime(line.modDate, dateTimeFormat) }}-->
            <!--          </div>-->
            <div class="ico_account ico_arr" />
          </div>
        </template>
      </li>
    </ul>
  </div>
</template>
<script>
import { unescape } from "lodash";
export default {
  name: "PayStatusApprover",
  props: {
    items: {
      // 결재선 목록
      type: Array,
      default: () => [],
    },
    aprvlLineType: {
      // 결재라인 타입
      type: Object,
      default: () => {},
    },
    aprvlStatus: {
      // 결재상태
      type: Object,
      default: () => {},
    },
    aprvlLineFlag: {
      // 결재타입 리스트
      type: Object,
      default: () => {},
    },
    dateTimeFormat: {
      // 날짜 포맷
      type: String,
      default: "YYYY-MM-DD HH:mm:ss",
    },
    drafter: {
      // 기안자
      type: Object,
      default: () => {},
    },
  },
  methods: {
    // 참조자 리스트만 필터링
    makeApprLiClass(item) {
      let classArray = [];
      if (
        (item.aprvlLineType === this.aprvlLineType.DRAFT ||
          item.aprvlStatus === this.aprvlStatus.APPROVAL_PAYMENT ||
          item.aprvlStatus === this.aprvlStatus.REJECT ||
          item.aprvlStatus === this.aprvlStatus.DRAFT_REJECT ||
          item.aprvlStatus === this.aprvlStatus.DRAFT_BEFORE_REJECT ||
          item.aprvlStatus === this.aprvlStatus.RECEIVE_PAYMENT) &&
        item.aprvlStatus !== this.aprvlStatus.BEFORE_PAYMENT
      ) {
        // console.log(item.aprvlLineType === this.aprvlLineType.DRAFT, item)
        classArray.push("done_on");
      }
      if ((item.delegateEmpNo != null && item.delegateEmpNo !== "") || item.holdYn === "Y") {
        classArray.push("instead_on");
      }
      return classArray;
    },
    makeApprStatusClass(item, isDelegate) {
      if (!isDelegate && item.delegateEmpNo != null && item.delegateEmpNo !== "") {
        return "tag_instead"; // 대결
      } else if (item.holdYn === "Y") {
        return "tag_hold"; // 보류
      } else if (item.aprvlStatus === this.aprvlStatus.APPROVAL_PAYMENT) {
        return "tag_approval"; // 승인
      } else if (
        item.aprvlStatus === this.aprvlStatus.REJECT ||
        item.aprvlStatus === this.aprvlStatus.DRAFT_REJECT ||
        item.aprvlStatus === this.aprvlStatus.DRAFT_BEFORE_REJECT
      ) {
        return "tag_return"; // 반려 (전체 반려, 기안자반려, 이전결재자 반려)
      } else if (item.aprvlLineType === this.aprvlLineType.DRAFT) {
        return "tag_report"; // 상신
      } else {
        return "tag_before";
      }
    },
    makeApprStatusName(item, isDelegate) {
      // console.log(item.aprvlStatus)
      if (!item.aprvlStatus) {
        return "";
      } else if (item.aprvlLineType === this.aprvlLineType.DRAFT) {
        return "상신";
      } else {
        return item.aprvlStatusName;
      }

      // if (
      //   !isDelegate &&
      //   item.delegateEmpNo != null &&
      //   item.delegateEmpNo !== ''
      // ) {
      //   return '대결'; // 대결
      // } else if (item.holdYn === 'Y') {
      //   return '보류'; // 보류
      // } else if (item.aprvlStatus === this.aprvlStatus.APPROVAL_PAYMENT) {
      //   return '승인'; // 승인
      // } else if (
      //   item.aprvlStatus === this.aprvlStatus.REJECT ||
      //   item.aprvlStatus === this.aprvlStatus.DRAFT_REJECT ||
      //   item.aprvlStatus === this.aprvlStatus.DRAFT_BEFORE_REJECT
      // ) {
      //   return '반려'; // 반려
      // } else if (item.aprvlLineType === this.aprvlLineType.DRAFT) {
      //   return '상신'; // 상신
      // } else {
      //   return '';
      // }
    },
    isShowDateTime(item) {
      // 결재, 승인, 반려 노출
      if (item.delegateEmpNo != null && item.delegateEmpNo !== "") {
        return false;
      }
      if (item.holdYn === "Y") {
        return false;
      }
      // if (item.aprvlLineType === this.aprvlLineType.DRAFT) return true;
      if (item.aprvlStatus === this.aprvlStatus.APPROVAL_PAYMENT) return true;
      return (
        item.aprvlStatus === this.aprvlStatus.REJECT ||
        item.aprvlStatus === this.aprvlStatus.DRAFT_REJECT ||
        item.aprvlStatus === this.aprvlStatus.DRAFT_BEFORE_REJECT
      );
    },
    formatDatetime(val, format) {
      if (!val) return "";
      if (typeof parseInt(val) === "number") {
        return this.$moment(val, "YYYYMMDDHHmmss").format(format);
      }
      return val;
    },
    unescape,
  },
};
</script>
