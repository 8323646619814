<template>
  <div class="box_g box_refer">
    <strong class="screen_out">참조자 목록</strong>
    <dl class="list_addition">
      <dt>참조자</dt>
      <dd>
        <template v-for="(line, idx) in items">
          <span v-if="idx !== 0" :key="idx" class="txt_comma">,</span>
          {{ unescape(line.deptName) }} {{ line.accountId }} ({{ line.empName }})
        </template>
      </dd>
    </dl>
  </div>
</template>
<script>
import { unescape } from "lodash";
export default {
  name: "PayStatusReferencer",
  props: {
    // 리스트
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    unescape,
  },
};
</script>
